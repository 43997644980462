
import { defineComponent } from 'vue'
import ArrowBack from '@/assets/svg/arrow-back.svg?inline'
import { Form, Field, ErrorMessage } from 'vee-validate'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import PhotoMock from '@/assets/svg/mock-super-admin.svg?inline'
import authHeader from '@/services/auth-header'
import Select from '@/components/atoms/CustomSelect.vue'
import CopyInstructor from '@/assets/svg/copy-instructor.svg?inline'
import Arrow from '@/assets/svg/select-arrow.svg?inline'

export default defineComponent({
  name: 'CreateSchool',
  data () {
    return {
      imagePreview: null,
      isPreview: false,
      name: '',
      errorSchool: null,
      file: null,
      schoolId: null,
      schoolData: {},
      editTeg: false,
      serverError: null,
      dataAdmins: [],
      adminIds: [],
      generateCode: null,
      errorGenerateCode: null,
      copied: false,
      dataTrial: [{
        title: '0',
        value: '0'
      },
      {
        title: '7',
        value: '7'
      },
      {
        title: '14',
        value: '14'
      },
      {
        title: '30',
        value: '30'
      },
      {
        title: '60',
        value: '60'
      }],
      trialPer: '30',
      trialPlacheholder: '30 days',
      adminPlacholder: 'Choose school admin',
      isActive: false,
      adminNames: []
    }
  },
  components: {
    ArrowBack,
    Form,
    Field,
    AlertBlock,
    PhotoMock,
    Select,
    CopyInstructor,
    Arrow
  },
  mounted () {
    document.addEventListener('click', this.handleOutsideClick)
    this.schoolId = this.$route.query.school
    if (this.$route.query.edit !== undefined) {
      this.editTeg = this.$route.query.edit
    }
    if (this.schoolId !== undefined) {
      this.getSchool(this.schoolId)
    }
    this.getAdminsList()
  },
  beforeUnmount () {
    document.removeEventListener('click', this.handleOutsideClick)
  },
  methods: {
    getSchool (id) {
      this.$store.dispatch('admins/getSchool', id).then(
        (res) => {
          this.schoolData = res.data
          this.name = this.schoolData.name
          this.generateCode = this.schoolData
          this.trialPlacheholder = this.schoolData.free_trial_days_interval + ' days'
          this.adminPlacholder = this.schoolData.admins[0].full_name
          this.schoolData.admins.forEach(admin => {
            this.adminIds.push(admin.id)
          })
          this.schoolData.admins.forEach(admin => {
            this.adminNames.push(admin.full_name)
          })
        },
        (error) => {
          this.errorSchool = error.response.data.errors
        }
      )
    },
    getAdminsList (params) {
      params = ''
      this.$store.dispatch('admins/getAdminsList', params).then(
        (res) => {
          this.dataAdmins = res.data
        },
        (error) => {
          this.errorAdmins = error.response.data.errors
        }
      )
    },
    createInstructor () {
      const API_URL = process.env.VUE_APP_URL
      const formData = new FormData()
      formData.append('name', this.name)
      formData.append('code', this.generateCode?.code)
      formData.append('free_trial_days_interval', this.trialPer)
      this.adminIds.forEach(id => {
        formData.append('admin_ids[]', id)
      })
      if (this.file !== null) {
        formData.append('logo', this.file)
      }
      if (this.editTeg === false) {
        this.axios.post(API_URL + 'super-admin/schools', formData, { headers: authHeader() }).then(() => {
          this.$router.push('/super-admin/school-list')
        },
        (error) => {
          this.errorSchool = error.response.data.errors
          this.serverError = error.response.data
        })
      } else {
        this.axios.post(API_URL + 'super-admin/schools/' + this.schoolId + '/update', formData, { headers: authHeader() }).then(() => {
          this.$router.push('/super-admin/school-list')
        },
        (error) => {
          this.errorSchool = error.response.data.errors
        })
      }
    },
    isChecked (id) {
      return this.adminIds.includes(id)
    },
    toggleInstructor (id, event, name) {
      if (event.target.checked) {
        if (!this.adminIds.includes(id)) {
          this.adminIds.push(id)
          this.adminNames.push(name)
        }
      } else {
        const index = this.adminIds.indexOf(id)
        if (index !== -1) {
          this.adminIds.splice(index, 1)
          this.adminNames.splice(index, 1)
        }
      }
    },
    toggleActiveClass () {
      this.isActive = !this.isActive
    },
    handleOutsideClick (event) {
      const multiSelectWrap = this.$refs.multiSelectWrap
      const multiSelectToggle = this.$refs.multiSelectToggle
      if (
        multiSelectWrap &&
        multiSelectToggle &&
        !multiSelectWrap.contains(event.target) &&
        !multiSelectToggle.contains(event.target)
      ) {
        this.isActive = false
      }
    },
    handleOptionSelected (selectedOptions) {
      selectedOptions.forEach(selectedOption => {
        console.log(selectedOption.checked, 'selectedOption.checked')
        if (selectedOption.checked) {
          if (!this.adminIds.includes(selectedOption.id)) {
            this.adminIds.push(selectedOption.id)
          }
        } else {
          const index = this.adminIds.indexOf(selectedOption.id)
          if (index !== -1) {
            this.adminIds.splice(index, 1)
          }
        }
      })
    },
    handleOptionSelectedTrial (selectedOption) {
      this.trialPer = selectedOption.value
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
      const formData = new FormData()
      formData.append('thumbnail', this.file)
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          this.imagePreview = reader.result
          this.isPreview = true
        }.bind(this),
        false
      )
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file)
        }
      }
    },
    generateInviteCode () {
      this.$store.dispatch('admins/generateInviteCode').then(
        (res) => {
          this.generateCode = res
          this.errorSchool = null
        },
        (error) => {
          this.errorGenerateCode = error.response.data.errors
        }
      )
    },
    copyText () {
      const textToCopy = this.generateCode.code
      const textarea = document.createElement('textarea')
      textarea.value = textToCopy
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
      this.copied = true

      setTimeout(() => {
        this.copied = false
      }, 3000)
    }
  }
})
