
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import CreateSchoolSections from '@/components/sections/super-admin/CreateSchool.vue'
import Header from '@/components/sections/Header.vue'

export default defineComponent({
  setup () {
    const { t } = useI18n({ useScope: 'global' })
    useMeta({
    })
  },
  name: 'CreateSchool',
  components: {
    CreateSchoolSections,
    Header
  }
})
